import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ChangelogPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>更新履歴 | アツクラLIVE!</title>
      </Helmet>
      <Wrapper>
        <H1>更新履歴</H1>
        <ChangelogItem>
          <H2>
            <SocialLinkLabel>2023-11-03</SocialLinkLabel>
          </H2>
          <Ul>
            <Li>
              10月末のアツクラ地図を追加しました。<Link to="/map">アツクラMAPページ</Link>で確認できます。
            </Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLinkLabel>2023-10-04</SocialLinkLabel>
          </H2>
          <Ul>
            <Li>
              9月末のアツクラ地図を追加しました。<Link to="/map">アツクラMAPページ</Link>で確認できます。
            </Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLinkLabel>2023-09-03</SocialLinkLabel>
          </H2>
          <Ul>
            <Li>
              8月末のアツクラ地図を追加しました。<Link to="/map">アツクラMAPページ</Link>で確認できます。
            </Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLinkLabel>2023-08-02</SocialLinkLabel>
          </H2>
          <Ul>
            <Li>
              7月末のアツクラ地図を追加しました。<Link to="/map">アツクラMAPページ</Link>で確認できます。
            </Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLinkLabel>2023-07-02</SocialLinkLabel>
          </H2>
          <Ul>
            <Li>
              6月末のアツクラ地図を追加しました。<Link to="/map">アツクラMAPページ</Link>で確認できます。
            </Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLinkLabel>2023-06-05</SocialLinkLabel>
          </H2>
          <Ul>
            <Li>
              5月末のアツクラ地図を追加しました。<Link to="/map">アツクラMAPページ</Link>で確認できます。
            </Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLinkLabel>2023-04-27</SocialLinkLabel>
          </H2>
          <Ul>
            <Li>
              アイコン表示における配信開始予定の表示条件を変更しました。
              <br />
              変更前: 配信開始予定の30分前から表示
              <br />
              変更後: 配信開始予定の60分前から60分後(配信が開始されなかった場合)まで表示
            </Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLink href="https://twitter.com/oyaniwatori/status/1649026364777922560">
              <SocialLinkLabel>2023-04-20</SocialLinkLabel>
              <SocialLinkIcon src="/images/icon/twitter.svg" />
            </SocialLink>
          </H2>
          <Ul>
            <Li>
              <Link to="/members/kitakubu">帰宅部チャンネル</Link>のアツクラ関連動画の取り扱いを開始しました。
            </Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLink href="https://twitter.com/oyaniwatori/status/1640741467634421761">
              <SocialLinkLabel>2023-03-29</SocialLinkLabel>
              <SocialLinkIcon src="/images/icon/twitter.svg" />
            </SocialLink>
          </H2>
          <Ul>
            <Li>スマホやPCなどのウィジェット表示に特化したウィジェットモードを追加しました。</Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLink href="https://twitter.com/oyaniwatori/status/1637313173417254914">
              <SocialLinkLabel>2023-03-19</SocialLinkLabel>
              <SocialLinkIcon src="/images/icon/twitter.svg" />
            </SocialLink>
          </H2>
          <Ul>
            <Li>各アツクラメンバーページの統計情報を月別で見れるようにしました。</Li>
            <Li>各アツクラメンバーページの統計情報の詳細の開閉状態を次回閲覧時も引き継ぐようにしました。</Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLink href="https://twitter.com/oyaniwatori/status/1627269268302876673">
              <SocialLinkLabel>2023-02-19</SocialLinkLabel>
              <SocialLinkIcon src="/images/icon/twitter.svg" />
            </SocialLink>
          </H2>
          <Ul>
            <Li>各アツクラメンバーページに、そのメンバーの配信・動画を元にした切り抜き動画のリストを追加しました。</Li>
            <Li>
              動画の関連動画（切り抜き動画、同じ時間の他メンバーのライブ配信、（切り抜き動画の）元動画）を見ることができるようになりました。動画下部の▼をクリックすると表示されます。
              <br />
              通常の状態で、それぞれの関連動画の数がアイコンと共に表示されるようになりました。
            </Li>
            <Li>
              動画一覧ページで、「日付でまとめる」をチェックすることで、動画を日付ごとのグルーピングができるようになりました。
              <br />
              また、「日付でまとめる」が有効な場合、動画の公開日時が日時で表示されるようになりました。「日付でまとめる」が無効な場合は、これまで通り相対表示（◯時間前）になります。
            </Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLink href="https://twitter.com/oyaniwatori/status/1625532316289482752">
              <SocialLinkLabel>2023-02-15</SocialLinkLabel>
              <SocialLinkIcon src="/images/icon/twitter.svg" />
            </SocialLink>
          </H2>
          <Ul>
            <Li>
              <Link to="/map">アツクラMAPページ</Link>を追加しました
            </Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLink href="https://twitter.com/oyaniwatori/status/1619026179700457473">
              <SocialLinkLabel>2023-01-28</SocialLinkLabel>
              <SocialLinkIcon src="/images/icon/twitter.svg" />
            </SocialLink>
          </H2>
          <Ul>
            <Li>トップページにアツクラの現在の動画本数を表示するようにしました</Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLink href="https://twitter.com/oyaniwatori/status/1616643106769891328">
              <SocialLinkLabel>2023-01-21</SocialLinkLabel>
              <SocialLinkIcon src="/images/icon/twitter.svg" />
            </SocialLink>
          </H2>
          <Ul>
            <Li>切り抜き動画の取り扱いを開始しました。</Li>
            <Li>
              メニューを追加し、各種情報にアクセスしやすくしました。（画面右上の
              <Icon src="/images/icon/menu.svg" />
              です）
            </Li>
            <Li>
              LIVEモードでオフラインメンバーの表示方法のオプション変更。「もうすぐ配信開始のみ」を選択可能に
              <br />
              30分以内に配信開始予定のメンバーを表示します。
            </Li>
            <Li>LIVEモードで「プレミア公開ラベルの表示」「配信開始予定ラベルの表示」を選択できるようになりました。</Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLink href="https://twitter.com/oyaniwatori/status/1615514086816772096">
              <SocialLinkLabel>2023-01-18</SocialLinkLabel>
              <SocialLinkIcon src="/images/icon/twitter.svg" />
            </SocialLink>
          </H2>
          <Ul>
            <Li>
              トップページおよびメンバーページで表示されている、公開済みの動画を「ライブ配信アーカイブ」「公開中の動画」と種類ごとにセクション分けしました。
            </Li>
            <Li>ライブ配信アーカイブのソート順を配信終了時間に変更しました。</Li>
            <Li>一部のスマートフォンで意図せずPC向け表示になっていたのを修正しました。</Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLink href="https://twitter.com/oyaniwatori/status/1614650824399155200">
              <SocialLinkLabel>2023-01-16</SocialLinkLabel>
              <SocialLinkIcon src="/images/icon/twitter.svg" />
            </SocialLink>
          </H2>
          <Ul>
            <Li>
              LIVEモードページを全画面アプリとして保存できるようになりました。
              <br />
              LIVEモードページにアクセスし、カスタマイズ（右上の歯車）の中の「アプリモード」をクリックし、表示された画面をホーム画面に追加してください。
            </Li>
            <Li>
              スマートフォンやタブレットで、LIVEモードページやメンバーページをホーム画面に追加しても、トップページになってしまっていたのを修正しました。
            </Li>
          </Ul>
        </ChangelogItem>
        <ChangelogItem>
          <H2>
            <SocialLink href="https://twitter.com/atsukurabot/status/1613904346365972482">
              <SocialLinkLabel>2023-01-13</SocialLinkLabel>
              <SocialLinkIcon src="/images/icon/twitter.svg" />
            </SocialLink>
          </H2>
          <Ul>
            <Li>アツクラLIVE!リリース</Li>
          </Ul>
        </ChangelogItem>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding-bottom: 96px;

  a {
    text-decoration: underline;
  }
`;

const H1 = styled.h1`
  margin-top: 48px;
  margin-bottom: 12px;
`;

const ChangelogItem = styled.div``;

const H2 = styled.h2`
  margin-bottom: 12px;
`;

const Ul = styled.ul`
  margin-bottom: 48px;
`;
const Li = styled.li`
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -1em;
  padding-left: 1em;
  margin: 12px 0;
`;

const Icon = styled.img`
  display: inline;
  width: 14px;
  height: 14px;
  margin: 0 4px;
`;

const SocialLink = styled.a.attrs(() => ({ target: '_blank' }))`
  display: inline-block;
`;
const SocialLinkIcon = styled.img`
  display: inline-block;
  width: 24px;
  vertical-align: middle;
`;
const SocialLinkLabel = styled.span`
  display: inline-block;
  line-height: 1.6;
  vertical-align: middle;
  margin-right: 4px;
`;
