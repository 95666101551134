import React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { MemberSummaryList } from '../../models/Member';
import FixedRatioBox from '../atoms/FixedRatioBox';
import { ConfigurableMemberStatus, ConfigurableMemberStatusOptions } from '../organisms/MemberStatus';

type WidgetPageTemplateProps = {
  className?: string;
  members: MemberSummaryList;
};

export const WidgetPageTemplate: React.FC<WidgetPageTemplateProps> = ({ className, members }) => {
  const [searchParams] = useSearchParams();
  let maxRows = Number(searchParams.get('maxrows') || '6');
  maxRows = isNaN(maxRows) ? 6 : maxRows;
  let minRows = Number(searchParams.get('minrows') || '3');
  minRows = isNaN(minRows) ? 3 : minRows;
  let aspect = Number(searchParams.get('aspect') || '2.12');
  aspect = isNaN(aspect) ? 2.12 : aspect;
  const placeholder = searchParams.get('placeholder') === 'image' ? 'image' : 'text';

  const onlineMembers = members.filterByStatus(['online', 'premiere']).count;
  const rows = Math.max(minRows, Math.min(maxRows, onlineMembers));
  const options: ConfigurableMemberStatusOptions = {
    rows: rows,
    gap: 2,
    padding: 2,
    groupNameFontSize: 0,
    memberNameFontSize: 0,
    directVideoLink: false,
    disableOfflineMemberClick: true,
    showPremiereLabel: true,
    showScheduleLabel: false,
    offlineVisibility: 'hidden',
    offlineStyle: 'grayscale',
  };

  return (
    <Wrapper className={className}>
      <FixedRatioBox ratio={1 / aspect}>
        <Centering>
          {onlineMembers > 0 ? (
            <MemberStatusWrapper>
              <StyledMemberStatus members={members} options={options} />
            </MemberStatusWrapper>
          ) : (
            <>
              {placeholder === 'image' ? (
                <Image src="/images/image/atsukura.png" />
              ) : (
                <Placeholder>
                  <BlockText>現在ライブ配信中の</BlockText>
                  <BlockText>メンバーはいません</BlockText>
                </Placeholder>
              )}
            </>
          )}
        </Centering>
      </FixedRatioBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -16px;
`;

const Centering = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const MemberStatusWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  padding: 16px;
`;

const StyledMemberStatus = styled(ConfigurableMemberStatus)`
  height: 100%;
  overflow: hidden;
`;

const Placeholder = styled.div`
  padding: calc(var(--placeholder-font-size) * 0.5) 0;
  text-align: center;
`;

const BlockText = styled.div`
  display: inline-block;
  font-size: calc(100vw / 22);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
