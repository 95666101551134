import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { SwitchImage } from '../atoms/SwitchImage';
import { usePrefetchImages } from '../hooks';

const ATSUKURA_MAPS = {
  '2023-start': '2023年はじめ',
  '2023-01': '2023年1月末',
  '2023-02': '2023年2月末',
  '2023-03': '2023年3月末',
  '2023-04': '2023年4月末',
  '2023-05': '2023年5月末',
  '2023-06': '2023年6月末',
  '2023-07': '2023年7月末',
  '2023-08': '2023年8月末',
  '2023-09': '2023年9月末',
  '2023-10': '2023年10月末',
  '2023-11': '2023年11月末',
} as const;

type AtsukuraMapKey = keyof typeof ATSUKURA_MAPS;
const MAP_IMAGE_URLS = Object.keys(ATSUKURA_MAPS);

export const MapPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const hash = location.hash.replace('#', '');
  const defaultMap = useMemo(() => {
    return MAP_IMAGE_URLS[MAP_IMAGE_URLS.length - 1] as AtsukuraMapKey;
  }, []);
  const filteredHash = Object.keys(ATSUKURA_MAPS).includes(hash) ? (hash as AtsukuraMapKey) : defaultMap;
  const [selectedMapKey, setSelectedMapKey] = useState<AtsukuraMapKey>(filteredHash);
  const setMapKey = useCallback(
    (mapKey: AtsukuraMapKey) => {
      setSelectedMapKey(mapKey);
      navigate(`#${mapKey}`);
    },
    [navigate],
  );
  const [displayOverlay, setDisplayOverlay] = useState(false);
  usePrefetchImages(MAP_IMAGE_URLS);

  return (
    <>
      <Helmet>
        <title>アツクラMAP | アツクラLIVE!</title>
        <meta property="og:image" content={`https://atsukura.live/images/map/${selectedMapKey}.png`} />
      </Helmet>
      <Wrapper>
        <Title>アツクラMAP</Title>
        <ImageWrapper onClick={() => setDisplayOverlay((overlay) => !overlay)}>
          <StyledSwitchImage src={`/images/map/${selectedMapKey}.png`} transitionDelay={180} />
          {displayOverlay && <OverlayImage src={`/images/map/map-overlay.png`} />}
        </ImageWrapper>
        <MonthContainer>
          {Object.entries(ATSUKURA_MAPS).map(([mapKey, label]) => (
            <Month
              key={mapKey}
              onClick={() => setMapKey(mapKey as AtsukuraMapKey)}
              selected={mapKey === selectedMapKey}
            >
              {label}
            </Month>
          ))}
        </MonthContainer>

        <CheckboxWrapper>
          <Checkbox
            id="display-overlay"
            type="checkbox"
            checked={displayOverlay}
            onChange={(event) => setDisplayOverlay(event.target.checked)}
          />
          <CheckboxLabel htmlFor="display-overlay">拠点情報を表示する</CheckboxLabel>
        </CheckboxWrapper>

        <Description>
          <Ul>
            <Li>配信画面から地図データを作成しているため、多少の色合いの違いや歪みが発生します。</Li>
            <Li>マップ画像の改変（書き込みなど）や転載は常識の範囲内で自由にどうぞ！</Li>
          </Ul>
        </Description>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding-bottom: 96px;
`;

const Title = styled.h1`
  margin-top: 48px;
  margin-bottom: 12px;
`;

const ImageWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const StyledSwitchImage = styled(SwitchImage)`
  width: 100%;
  max-width: 1140px;

  @media screen and (max-width: 430px) {
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
  }
`;

const OverlayImage = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 1140px;
  background: rgba(17, 17, 17, 0.4);

  @media screen and (max-width: 430px) {
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
  }
`;

const MonthContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Month = styled.a<{ selected: boolean }>`
  width: 120px;
  height: 48px;
  display: grid;
  place-items: center;
  color: ${({ selected }) => (selected ? '#f1f1f1' : '#999')};
  cursor: pointer;
  user-select: none;
`;

const Description = styled.div`
  padding: 24px 0;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 12px;
`;

const Checkbox = styled.input.attrs(() => ({ type: 'checkbox' }))`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const CheckboxLabel = styled.label`
  margin-left: 8px;
  user-select: none;
  font-size: 16px;
  cursor: pointer;
`;

const Ul = styled.ul`
  margin-bottom: 48px;
`;
const Li = styled.li`
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -20px;
  padding-left: 20px;
`;
