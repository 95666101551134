import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { MemberSummaryList } from '../../../models/Member';
import { useLocalStorage } from '../../hooks/storage';
import { ConfigurableMemberStatus, ConfigurableMemberStatusOptions } from '../../organisms/MemberStatus';
import { MemberStatusSetting } from '../../organisms/MemberStatusSetting';

type LivePageTemplateProps = {
  className?: string;
  members: MemberSummaryList;
};

const getDefaultSetting = (): ConfigurableMemberStatusOptions => {
  const isMobile = window.matchMedia('screen and (max-width: 430px)').matches;
  return isMobile
    ? {
        // モバイル向け初期値
        rows: 6,
        gap: 2,
        padding: 2,
        groupNameFontSize: 5,
        memberNameFontSize: 3,
        directVideoLink: false,
        disableOfflineMemberClick: false,
        showPremiereLabel: true,
        showScheduleLabel: true,
        offlineVisibility: 'all',
        offlineStyle: 'grayscale',
      }
    : {
        // PC向け初期値
        rows: Math.floor(window.innerWidth / 180),
        gap: 1,
        padding: 2,
        groupNameFontSize: 4,
        memberNameFontSize: 1.5,
        directVideoLink: false,
        disableOfflineMemberClick: false,
        showPremiereLabel: true,
        showScheduleLabel: true,
        offlineVisibility: 'all',
        offlineStyle: 'grayscale',
      };
};

export const LivePageTemplate: React.FC<LivePageTemplateProps> = ({ className, members }) => {
  const [searchParams] = useSearchParams();
  const forSaveStandaloneMode = searchParams.get('standalone') === 'true';

  const [memberStatusOptions, setMemberStatusOptions] = useLocalStorage<ConfigurableMemberStatusOptions>(
    'MEMBER_STATUS_OPTIONS',
    getDefaultSetting(),
    true,
  );

  useEffect(() => {
    setMemberStatusOptions(memberStatusOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isOpenSetting, setIsOpenSetting] = useState(false);
  const handleOnClickReset = useCallback(() => {
    if (window.confirm('設定をリセットしますか？')) {
      setMemberStatusOptions(getDefaultSetting());
    }
  }, [setMemberStatusOptions]);

  return (
    <>
      <Wrapper className={className}>
        <FirstView>
          <MemberStatusWrapper>
            <StyledMemberStatus members={members} options={memberStatusOptions} />
          </MemberStatusWrapper>
        </FirstView>
        <CautionContainer>
          <Caution>※ 状態の反映は最大10分程度遅れることがあります</Caution>
        </CautionContainer>
      </Wrapper>

      <SettingMenu onClick={() => setIsOpenSetting((isOpenSetting) => !isOpenSetting)}>
        <SettingAlert>カスタマイズ</SettingAlert>
        <SettingIcon />
      </SettingMenu>
      {isOpenSetting && (
        <>
          <MemberStatusSettingWrapper>
            <MemberStatusSetting options={memberStatusOptions} onChangeOptions={setMemberStatusOptions} />
            <Button onClick={() => setIsOpenSetting(false)}>閉じる</Button>
            <SettingFooter>
              <Action onClick={handleOnClickReset}>設定をリセット</Action>
              <Action as="a" href="/live?standalone=true" target="_blank">
                アプリモード
              </Action>
            </SettingFooter>
          </MemberStatusSettingWrapper>
        </>
      )}
      {forSaveStandaloneMode && (
        <StandaloneDescription>
          全画面アプリとして保存するには、この状態で「ホーム画面に追加」（iOSの場合）を行ってください。
          <br />
          <br />
          ホーム画面への追加が終わったら下の「閉じる」ボタンをクリックしてタブを閉じてください。
          <Button onClick={() => window.close()}>閉じる</Button>
        </StandaloneDescription>
      )}
    </>
  );
};

const Wrapper = styled.div``;
const CautionContainer = styled.div`
  text-align: right;
  overflow: hidden;
`;

const Caution = styled.div`
  display: inline-block;
  text-align: left;

  font-size: 14px;
  line-height: 2.285;
  height: 32px;
  color: #999;
  white-space: nowrap;
`;

const FirstView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100svh - 16px * 2 - 32px * 2);
  justify-content: start;
  min-height: calc(100svh - 16px * 2 - 32px * 2);
  max-height: calc(100svh - 16px * 2 - 32px * 2);
`;

const MemberStatusWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
`;

const StyledMemberStatus = styled(ConfigurableMemberStatus)`
  height: 100%;
  overflow: hidden;
`;

const SettingMenu = styled.div`
  position: fixed;
  top: 0;
  right: 48px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  z-index: 1000;
`;

const SettingAlert = styled.div`
  margin-right: -12px;
  line-height: 48px;
  color: #f1f1f1;
  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  animation: fade-out 500ms ease-in 2s 1 alternate forwards running;
`;

const SettingIcon = styled.img.attrs(() => ({ src: '/images/icon/setting.svg' }))`
  display: block;
  padding: 16px;
  width: 48px;
  height: 48px;
  opacity: 0.8;
`;

const MemberStatusSettingWrapper = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  border: 1px solid white;
  background: #0f0f0f;
  @media screen and (max-width: 430px) {
    width: 90%;
    left: 5%;
  }
`;

const Button = styled.div`
  margin: 36px 24px 24px;
  width: calc(100% - 48px);
  border: 1px solid white;
  padding: 12px;
  cursor: pointer;
  text-align: center;

  :hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const SettingFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Action = styled.div`
  display: inline-block;
  margin: 8px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const StandaloneDescription = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 32px;
  background: rgba(0, 0, 0, 0.98);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
